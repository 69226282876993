import { ThemeProvider } from '@mui/material';
import getServerFlags from 'api/getServerFlags';
import Favicon from 'components/Favicon';
import MaintenanceBanner from 'components/MaintenanceBanner';
import RuntimeConfigProvider from 'state/runtime-config-provider';
import useSWR from 'swr';
import { theme } from 'theme/theme';
import FetchUserData from '../FetchUserData';
import UnleashFlagsProvider from '../UnleashFlagsProvider';
import UtmTagsProvider from '../UtmTagsProvider';
import { StyledToastContainer } from './AppProviders.styled';
import { ReactNode } from 'react';
import { MantineProvider } from '@mantine/core';
import Head from 'next/head';
import axios from 'axios';
import { RuntimeConfig } from 'types/runtime-config';

const serverFlagsFetcher = async () => {
    const { data } = await getServerFlags();
    return data;
};

const AppProviders: React.FC<{ children: ReactNode }> = ({ children }) => {
    /*
        FIXME:
        Start: use this hack before we turn on the SSR/SSG
    */
    const { data: flags } = useSWR('/flags/', serverFlagsFetcher, {
        revalidateOnFocus: false,
    });

    const fetcher = async () => {
        const fetchUrl = '/api/runtime-config/';
        const request = axios.get<RuntimeConfig>(fetchUrl);

        const { data } = await request;
        return data;
    };

    const { data: fetchedConfig } = useSWR('config', fetcher);

    const GTM_ID = fetchedConfig?.NEXT_PUBLIC_GTM_ID;

    const GTMScript = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GTM_ID}');`;

    return (
        <>
            <Head>
                {GTM_ID && (
                    <script
                        id="GTMOnReadyScript"
                        dangerouslySetInnerHTML={{
                            __html: GTMScript,
                        }}
                    />
                )}
            </Head>
            {/* FIXME: End */}
            <RuntimeConfigProvider>
                <Favicon />
                <UtmTagsProvider />
                <FetchUserData />
                <UnleashFlagsProvider>
                    <ThemeProvider theme={theme}>
                        <MantineProvider>
                            <StyledToastContainer />
                            <MaintenanceBanner {...flags} canBeClosed={true} />
                            {children}
                        </MantineProvider>
                    </ThemeProvider>
                </UnleashFlagsProvider>
            </RuntimeConfigProvider>
        </>
    );
};

export default AppProviders;
