import { AppProps } from 'next/app';
import '../index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import AppProviders from 'app/AppProviders';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
    return (
        <AppProviders>
            <Component {...pageProps} />
        </AppProviders>
    );
};

export default MyApp;
