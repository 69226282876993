import FlagProvider, { IConfig } from '@unleash/proxy-client-react';
import { ReactNode } from 'react';
import useClientRuntimeConfig from 'state/useClientRuntimeConfig';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

const UnleashFlagsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const { config: clientConfig } = useClientRuntimeConfig();
    const cashedConfig = getCachedRuntimeConfig();

    const { NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL: CASHED_UNLEASH_URL } =
        cashedConfig;

    const {
        NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL: CLIENT_UNLEASH_URL,
        NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: CLIENT_UNLEASH_TOKEN,
        UNLEASH_APP_NAME: CLIENT_UNLEASH_NAME,
    } = clientConfig;

    const unleashConfig = {
        url: CLIENT_UNLEASH_URL || CASHED_UNLEASH_URL,
        clientKey: CLIENT_UNLEASH_TOKEN || 'not-used',
        appName: CLIENT_UNLEASH_NAME || 'not-used',
        disableRefresh: true,
    } as IConfig;

    if (!CLIENT_UNLEASH_URL || !CASHED_UNLEASH_URL) return null;

    return <FlagProvider config={unleashConfig}>{children}</FlagProvider>;
};

export default UnleashFlagsProvider;
